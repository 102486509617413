"use client";

import { useRouter } from "next/navigation";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import styles from "./PropertySearchBar.module.scss";
import { Search } from "@/common/icons/Search/Search";

type Props = {
  /** top: トップページ用, menu: メニュー用 */
  type: "top" | "menu";
  onSearch?: () => void;
};

/**
 * 物件名検索バー
 */
const PropertySearchBar = (props: Props): JSX.Element => {
  const containerClass = `${styles.container} ${styles[props.type]}`;
  const inputClass = `${styles.input} ${styles[props.type]}`;
  const buttonClass = `${styles.button} ${styles[props.type]}`;

  const isMounted = useRef(true);
  const router = useRouter();
  const [searchWord, setSearchWord] = useState<string>("");
  const [searching, setSearching] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchWord(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doSearch();
  };

  const doSearch = () => {
    if (!searchWord) return;
    if (searching) return;

    setSearching(true);

    if (typeof props.onSearch === "function") {
      props.onSearch();
    }
    router.push(`/list?k=${searchWord}`);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
      setSearching(false);
    };
  }, []);

  return (
    <form className={containerClass} action="" onSubmit={handleSubmit}>
      <input
        className={inputClass}
        value={searchWord}
        onChange={handleInputChange}
        type="search"
        placeholder="物件名を入力"
      />
      <button className={buttonClass} type="submit" disabled={searching}>
        <Search size="w19"></Search>
      </button>
    </form>
  );
};

export default PropertySearchBar;
