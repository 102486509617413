"use client";

import { FC, ReactNode, useState } from "react";
import { GoogleMapIsOpenContext, SavedConditionPopupContext, SurveyPopupContext } from "~/contexts";

export const GlobalStateWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const [savedConditionPopupIsOpen, setSavedConditionPopupIsOpen] = useState<boolean>(false);
  const [savedConditionCount, setSavedConditionCount] = useState<number>(0);
  const [surveyPopupIsOpen, setSurveyPopupIsOpen] = useState<boolean>(false);
  const [surveyPopupIsOpenFromSavedCondition, setSurveyPopupIsOpenFromSavedCondition] =
    useState<boolean>(false);
  const [shouldOpenSurveyPopup, setShouldOpenSurveyPopup] = useState<boolean>(false);
  const [googleMapIsOpen, setGoogleMapIsOpen] = useState<boolean>(false);
  return (
    <SavedConditionPopupContext.Provider
      value={{
        savedConditionPopupIsOpen,
        setSavedConditionPopupIsOpen,
        savedConditionCount,
        setSavedConditionCount,
      }}
    >
      <SurveyPopupContext.Provider
        value={{
          surveyPopupIsOpen,
          setSurveyPopupIsOpen,
          surveyPopupIsOpenFromSavedCondition,
          setSurveyPopupIsOpenFromSavedCondition,
          shouldOpenSurveyPopup,
          setShouldOpenSurveyPopup,
        }}
      >
        <GoogleMapIsOpenContext.Provider value={{ googleMapIsOpen, setGoogleMapIsOpen }}>
          {children}
        </GoogleMapIsOpenContext.Provider>
      </SurveyPopupContext.Provider>
    </SavedConditionPopupContext.Provider>
  );
};
