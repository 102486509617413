import styles from "./Search.module.scss";

type Props = {
  size: "w16" | "w19";
};

/**
 * 検索アイコン
 */
export const Search = (props: Props): JSX.Element => {
  const cls = styles[props.size];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.631"
      height="16.63"
      viewBox="0 0 16.631 16.63"
      className={cls}
    >
      <path
        d="M-4121.572,1789.221a.241.241,0,0,1-.171-.071l-3.263-3.264a.123.123,0,0,0-.086-.035.116.116,0,0,0-.068.021,7.241,7.241,0,0,1-4.139,1.29,7.239,7.239,0,0,1-5.151-2.134,7.239,7.239,0,0,1-2.134-5.152,7.238,7.238,0,0,1,2.134-5.151,7.237,7.237,0,0,1,5.151-2.134,7.238,7.238,0,0,1,5.152,2.134,7.276,7.276,0,0,1,.844,9.289.121.121,0,0,0,.014.155l3.264,3.263a.241.241,0,0,1,.071.172.241.241,0,0,1-.071.172l-1.374,1.374A.243.243,0,0,1-4121.572,1789.221Zm-7.728-14.2a4.825,4.825,0,0,0-3.434,1.422,4.825,4.825,0,0,0-1.423,3.434,4.825,4.825,0,0,0,1.423,3.434,4.826,4.826,0,0,0,3.434,1.423,4.826,4.826,0,0,0,3.435-1.423,4.824,4.824,0,0,0,1.42-3.434,4.826,4.826,0,0,0-1.42-3.434A4.825,4.825,0,0,0-4129.3,1775.02Z"
        transform="translate(4136.586 -1772.591)"
        fill="#2391dc"
      />
    </svg>
  );
};
