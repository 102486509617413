"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { ReactNode, Suspense, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Modal from "react-modal";
import { ApolloWrapper } from "@/layouts/ApolloProvider";
import { GlobalStateWrapper } from "@/layouts/GlobalStateWrapper";
import StorageWrapper from "@/layouts/StorageWrapper";
import TagManagerWrapper from "@/layouts/TagManagerWrapper";
import { CurrentReferrer } from "~/features/visitor-context/CurrentReferrer";
import { FirstReferrer } from "~/features/visitor-context/FirstReferrer";
import { FirstVisitTimestamp } from "~/features/visitor-context/FirstVisitTimestamp";

const RECAPTCHA_CLIENT_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY as string;

Modal.setAppElement("body");

const ClientWrapper = ({ children }: { children: ReactNode }) => {
  const searchParams = useSearchParams();
  const path = usePathname();
  const queryString = searchParams.toString();
  const pathName = queryString ? `${path}?${queryString}` : path;

  useEffect(() => {
    // TODO: クエリ変更時にスクロール位置がリセットされないバグがあるので一時対応
    // source:　https://github.com/vercel/next.js/issues/45187
    // ブラウザバックで遷移した場合はスクロール位置が存在するので何もしない
    if (window.performance.navigation.type === 2) return;

    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathName]);

  useEffect(() => {
    FirstReferrer.initialize();
    CurrentReferrer.initialize();
    FirstVisitTimestamp.initialize();
  });

  return (
    <ApolloWrapper>
      <GoogleReCaptchaProvider
        reCaptchaKey={RECAPTCHA_CLIENT_KEY}
        language="ja"
        useEnterprise={true}
      >
        <GlobalStateWrapper>
          <TagManagerWrapper>
            <Suspense>
              <StorageWrapper>{children}</StorageWrapper>
            </Suspense>
          </TagManagerWrapper>
        </GlobalStateWrapper>
      </GoogleReCaptchaProvider>
    </ApolloWrapper>
  );
};

export default ClientWrapper;
