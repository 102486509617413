import Dexie, { Table } from "dexie";

import { FavoriteRoomInterface } from "./interfaces/FavoriteRoomInterface";
import { SavedSearchInterface } from "./interfaces/SavedSearchInterface";
import { SearchHistoryInterface } from "./interfaces/SearchHistoryInterface";
import { ViewHistoryInterface } from "./interfaces/ViewHistoryInterface";

/**
 * airdoor用のIndexedDBを扱うためのラクス
 */
export class AirdoorDatabase extends Dexie {
  viewHistories: Table<ViewHistoryInterface, number>;
  favoriteRooms: Table<FavoriteRoomInterface, number>;
  savedSearches: Table<SavedSearchInterface, number>;
  searchHistories: Table<SearchHistoryInterface, number>;

  constructor() {
    super("AirdoorDatabase");

    // NOTE: スキーマに変更を加える際には、新しいバージョンを追加する（see: https://dexie.org/docs/Tutorial/Design#database-versioning）
    this.version(1).stores({
      viewHistories: "roomId, createdAt",
      favoriteRooms: "roomId, createdAt",
    });

    this.version(2).stores({
      savedSearches: "++id, path, conditions, createdAt",
    });

    this.version(3).stores({
      searchHistories: "++id, path, conditions, createdAt",
    });
  }
}

export const db = new AirdoorDatabase();
