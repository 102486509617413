"use client";

import { ReactNode, useEffect, useState } from "react";

type ShowAfterMountedProps = {
  children: ReactNode;
};

/**
 * クライアントサイドでのみ表示可能なコンポーネントのためのラッパーコンポーネント
 *
 * [使用方法]
 * ```
 * <ShowAfterMounted>
 *   <SampleComponent></SampleComponent>
 * </ShowAfterMounted>
 * ```
 */
const ShowAfterMounted = (props: ShowAfterMountedProps): JSX.Element => {
  const [childrenShowable, setChildrenShowable] = useState(false);

  useEffect(() => {
    setChildrenShowable(true);
  }, []);

  if (!childrenShowable) {
    return <></>;
  }

  return <>{props.children}</>;
};

export default ShowAfterMounted;
