import { FC } from "react";
import Modal from "react-modal";
import SurveyWidget from "@/common//partials/SurveyContent/SurveyWidget";
import CommonModalStyles from "@/common/modals/CommonModal.module.scss";
import styles from "@/common/modals/SurveyModal.module.scss";
import CloseIcon from "/public/static/svgs/common/close_fill_blue.svg";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  fromSavedCondition?: boolean;
};

const SurveyModal: FC<Props> = ({ isOpen, setIsOpen, fromSavedCondition }) => {
  const utmCampaign = fromSavedCondition ? "after_save_condition_popup" : "after_favorite_popup";

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={{
        base: CommonModalStyles.overlay,
        afterOpen: CommonModalStyles.overlayAfter,
        beforeClose: CommonModalStyles.overlayBefore,
      }}
      className={{
        base: styles.body,
        afterOpen: CommonModalStyles.bodyAfter,
        beforeClose: CommonModalStyles.bodyBefore,
      }}
      closeTimeoutMS={500}
    >
      <div className={styles.modalInner}>
        <button className={styles.closeButton} onClick={() => setIsOpen(false)}>
          <CloseIcon></CloseIcon>
        </button>

        <div className={styles.header}>
          <p className={styles.title}>
            エアドアのご利用
            <br className="is-sp-only" />
            ありがとうございます
          </p>
          <p className={styles.description}>
            4つの質問の簡単なアンケートです。ぜひご意見をお聞かせください。
          </p>
        </div>
        <div className={styles.contentWrap}>
          <SurveyWidget campaign={utmCampaign} />
        </div>
        <button className={styles.primaryButton} onClick={() => setIsOpen(false)}>
          閉じる
        </button>
      </div>
    </Modal>
  );
};

export default SurveyModal;
