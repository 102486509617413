import Link from "next/link";
import styles from "./Notification.module.scss";
import dayjs from "~/lib/dayjs";

const Notification: React.FunctionComponent = () => {
  const isShow = dayjs().tz().isBefore(new Date("2024/8/19"));

  return (
    <>
      {(isShow && (
        <Link
          href="https://airdoor.notion.site/1be6f76a48d844d7815b7ea8d1989a35"
          prefetch={false}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.notificationBanner}>
            【お知らせ】夏季休暇中の物件情報及び内見可否について
          </div>
        </Link>
      )) ||
        ""}
    </>
  );
};

export default Notification;
