import { ApolloLink, HttpLink } from "@apollo/client";

export enum GraphqlVersion {
  V2 = "v2",
}

const apolloHttpLinkBase = {
  credentials: "include",
};

export const ApolloServerSideLink = ApolloLink.split(
  (operation) => operation.getContext().api === GraphqlVersion.V2,
  new HttpLink({ ...apolloHttpLinkBase, uri: process.env.NEXT_PUBLIC_SSR_GRAPHQL_V2_URL }),
  new HttpLink({ uri: process.env.NEXT_PUBLIC_SSR_GRAPHQL_URL }),
);

export const ApolloAppRouterLink = ApolloLink.split(
  (operation) => operation.getContext().api === GraphqlVersion.V2,
  new HttpLink({ ...apolloHttpLinkBase, uri: process.env.NEXT_PUBLIC_SSR_GRAPHQL_V2_URL }),
  new HttpLink({ uri: process.env.NEXT_PUBLIC_SSR_GRAPHQL_URL }),
);

export const ApolloProviderLink = ApolloLink.split(
  (operation) => operation.getContext().api === GraphqlVersion.V2,
  new HttpLink({ ...apolloHttpLinkBase, uri: process.env.NEXT_PUBLIC_GRAPHQL_V2_URL }),
  new HttpLink({ uri: process.env.NEXT_PUBLIC_GRAPHQL_URL }),
);
