import { db } from "../db";
import { FavoriteRoomInterface } from "../interfaces/FavoriteRoomInterface";
import dayjs from "~/lib/dayjs";

/**
 * お気に入りモデル
 */
export class FavoriteRoom implements FavoriteRoomInterface {
  static readonly MAX_FAVORITE_COUNT = 50;

  roomId: number;
  createdAt: Date;

  constructor(roomId: number, createdAt?: Date) {
    this.roomId = roomId;
    this.createdAt = createdAt;
  }

  /**
   * お気に入りに保存する
   */
  async save(): Promise<void> {
    const createdAt = dayjs.tz().toDate();
    await FavoriteRoom.add(this.roomId, createdAt);
    this.createdAt = createdAt;
  }

  /**
   * お気に入りを解除する
   */
  async delete(): Promise<void> {
    await FavoriteRoom.delete(this.roomId);
    this.createdAt = undefined;
  }

  /**
   * お気に入り情報を取得する
   */
  static async get(roomId: number): Promise<FavoriteRoom | null> {
    const record = await db.favoriteRooms.get(roomId);
    return record ? new this(record.roomId, record.createdAt) : null;
  }

  /**
   * お気に入りに追加する
   */
  static async add(roomId: number, createdAt?: Date): Promise<void> {
    const count = await this.getCount();
    if (count >= this.MAX_FAVORITE_COUNT) {
      throw new ExceedMaxFavoriteCountError(
        "お気に入り登録の上限に達しました。他の物件をお気に入りから解除した後、再度お試しください。",
      );
    }

    createdAt = createdAt || dayjs.tz().toDate();
    await db.favoriteRooms.put({
      roomId: roomId,
      createdAt: createdAt,
    });
  }

  /**
   * お気に入りから削除する
   */
  static async delete(roomIds: number | number[]): Promise<void> {
    if (typeof roomIds === "number") {
      await db.favoriteRooms.delete(roomIds);
    } else if (Array.isArray(roomIds)) {
      for (const roomId of roomIds) {
        await db.favoriteRooms.delete(roomId);
      }
    } else {
      throw new TypeError();
    }
  }

  /**
   * お気に入りを全件取得する
   */
  static async getAll(): Promise<FavoriteRoom[]> {
    const records = await db.favoriteRooms.toArray((record) => record);
    return records.map((r) => new FavoriteRoom(r.roomId, r.createdAt));
  }

  /**
   * お気に入りを指定したIDを除き全件取得する
   */
  static async getAllExcept(ids: number[]): Promise<FavoriteRoom[]> {
    const records = await db.favoriteRooms
      .filter((record) => !ids.includes(record.roomId))
      .toArray((record) => record);
    return records.map((r) => new FavoriteRoom(r.roomId, r.createdAt));
  }

  /**
   * お気に入りの件数を取得する
   */
  static getCount(): Promise<number> {
    return db.favoriteRooms.count();
  }
}

/**
 * お気に入り登録の条件を超過した時のエラー
 */
export class ExceedMaxFavoriteCountError extends RangeError {}
