import LocalStorageUtil from "~/utils/localStorageUtil";

/**
 * 初回アクセス時の時刻を管理するシングルトンクラス
 *
 * セッション開始時に初期化する。
 */
export class FirstVisitTimestamp {
  private static STORAGE_KEY = "first-visit-timestamp";
  private static _instance: FirstVisitTimestamp | null = null;

  private constructor(private readonly _timestamp: string) {}

  /**
   * 初回アクセス時の時刻 (ISO 8601形式)
   */
  value() {
    return this._timestamp;
  }

  static get instance(): FirstVisitTimestamp {
    if (!this._instance) {
      throw new Error("FirstVisitTimestamp is not initialized. Please initialize it.");
    }
    return this._instance;
  }

  static initialize() {
    let timestamp = LocalStorageUtil.getItem<string>(this.STORAGE_KEY);
    if (timestamp === null) {
      timestamp = new Date().toISOString();
      LocalStorageUtil.setItem<string>(this.STORAGE_KEY, timestamp);
    }
    this._instance = new FirstVisitTimestamp(timestamp);
  }
}
