import styles from "./HeartWithCount.module.scss";

/** カウント付きハートアイコン */
export const HeartWithCount = ({ count }: { count: number }): JSX.Element => (
  <span className={styles.heartWithCount}>
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.heart}
    >
      <path
        d="M10.7692 16.6149C10.7298 16.6149 10.6911 16.6058 10.6559 16.5885C4.91256 13.7377 2 10.4368 2 6.77853C2.00164 5.47074 2.53007 4.21702 3.46943 3.29228C4.40879 2.36753 5.68257 1.84732 7.01102 1.8457C7.69893 1.84687 8.37922 1.98701 9.00989 2.25743C9.64057 2.52786 10.2082 2.9228 10.6775 3.41787C10.6892 3.43041 10.7033 3.44043 10.7191 3.44725C10.7349 3.45407 10.752 3.45748 10.7692 3.45741C10.7865 3.45736 10.8036 3.45381 10.8194 3.447C10.8351 3.44019 10.8494 3.43031 10.8612 3.41787C11.3306 2.92289 11.8982 2.52798 12.5288 2.25756C13.1595 1.98714 13.8398 1.84699 14.5277 1.8457C15.8561 1.84732 17.1297 2.36753 18.069 3.29228C19.0084 4.21702 19.5368 5.47074 19.5385 6.77853C19.5385 10.4348 16.6262 13.7377 10.8818 16.5885C10.8469 16.6057 10.8083 16.6148 10.7692 16.6149Z"
        stroke="#2391DC"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
    {count > 0 && <span className={styles.number}>{count}</span>}
  </span>
);
