import SessionStorageUtil from "~/utils/sessionStorageUtil";

/**
 * 現在のリファラーを管理するシングルトンクラス
 *
 * セッション開始時に初期化する。
 */
export class CurrentReferrer {
  private static STORAGE_KEY = "current-referrer";
  private static _instance: CurrentReferrer | null = null;

  private constructor(private readonly _referrer: string) {}

  value() {
    return this._referrer;
  }

  static get instance(): CurrentReferrer {
    if (!this._instance) {
      throw new Error("CurrentReferrer is not initialized. Please initialize it.");
    }
    return this._instance;
  }

  static initialize() {
    let referrer = SessionStorageUtil.getItem<string>(this.STORAGE_KEY);
    if (referrer === null) {
      referrer = document?.referrer || "";
      SessionStorageUtil.setItem<string>(this.STORAGE_KEY, referrer);
    }
    this._instance = new CurrentReferrer(referrer);
  }
}
