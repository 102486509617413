import { useSearchParams } from "next/navigation";
import { ReactNode, useEffect } from "react";
import { UTM_CAMPAIGN_STORAGE_KEY, UTM_SOURCE_STORAGE_KEY } from "~/const/campaign";
import SessionStorageUtil from "~/utils/sessionStorageUtil";

const StorageWrapper = ({ children }: { children: ReactNode }) => {
  const searchParams = useSearchParams();
  // URL に utm_source, utm_campaign が付与されている場合は SessionStorage に保持する。
  useEffect(() => {
    const utm_source = searchParams.get("utm_source");
    const utm_campaign = searchParams.get("utm_campaign");
    if (utm_source) {
      SessionStorageUtil.setItem(UTM_SOURCE_STORAGE_KEY, utm_source);
    }
    if (utm_campaign) {
      SessionStorageUtil.setItem(UTM_CAMPAIGN_STORAGE_KEY, utm_campaign);
    }
  }, [searchParams]);

  return <>{children}</>;
};

export default StorageWrapper;
