import Image from "next/image";
import Link from "next/link";
import React from "react";
import styles from "./Footer.module.scss";

const linkList1 = [
  { id: 1, name: "エアドアについて", url: "/about" },
  { id: 8, name: "エリアから探す", url: "/condition/region" },
  { id: 9, name: "路線・駅から探す", url: "/condition/route" },
  { id: 7, name: "すべての建物一覧", url: "/library?to=13" },
];
const linkList2 = [
  { id: 3, name: "よくある質問", url: "/static/qa" },
  { id: 4, name: "お問い合わせ", url: "https://forms.gle/km19CwSMfjPeMig67" },
  {
    id: 10,
    name: "アンケートに協力",
    url: "https://g8djtotv5zr.typeform.com/to/CW1807Ib",
  },
  { id: 2, name: "運営会社", url: "/static/corporation" },
  { id: 5, name: "利用規約", url: "/static/tos" },
  { id: 6, name: "プライバシーポリシー", url: "/static/pp" },
];

const Footer = (): JSX.Element => {
  return (
    <>
      <div className={`l-basic ${styles.body}`}>
        <div className="container">
          <Link className={styles.logo} href="/" prefetch={false}>
            <Image
              src={`/static/images/logo-white.png`}
              height={36}
              width={180}
              alt="エアドア"
              unoptimized
            ></Image>
          </Link>

          <div className="partition-2">
            <div>
              <div className={styles.links}>
                <ul>
                  {linkList1.map((link) => (
                    <li key={`footerList_${link.id}`}>
                      <Link href={link.url} prefetch={false}>
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <ul>
                  {linkList2.map((link) => (
                    <li key={`footerList_${link.id}`}>
                      <Link href={link.url} prefetch={false}>
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="partition-2">
            <div className={styles.sns}>
              <ul>
                <li>
                  <Link href="https://www.instagram.com/airdoor_jp/" prefetch={false}>
                    <Image
                      src={`/static/images/icon/instagram-white.png`}
                      height={29}
                      width={29}
                      alt="エアドア-instagram"
                    ></Image>
                  </Link>
                </li>
                <li>
                  <Link href="https://twitter.com/airdoor_jp" prefetch={false}>
                    <Image
                      src={`/static/images/icon/twitter-white.png`}
                      height={27}
                      width={33}
                      alt="エアドア-twitter"
                    ></Image>
                  </Link>
                </li>
              </ul>
              <span className={styles.c}>© airdoor, Inc. All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
