/** 履歴アイコン */
export const History = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.546"
    height="16.996"
    viewBox="0 0 18.546 16.996"
  >
    <path
      d="M-3643.77,1793.729a.241.241,0,0,1-.169-.068.244.244,0,0,1-.074-.174v-1.944a.242.242,0,0,1,.233-.243,6.051,6.051,0,0,0,5.838-6.067,6.078,6.078,0,0,0-6.071-6.072,6.1,6.1,0,0,0-6.07,5.948.12.12,0,0,0,.035.088.121.121,0,0,0,.087.036h1.18a.244.244,0,0,1,.22.14.244.244,0,0,1-.033.259l-2.517,3.021a.242.242,0,0,1-.187.088.243.243,0,0,1-.187-.088l-2.517-3.021a.245.245,0,0,1-.033-.259.244.244,0,0,1,.22-.14h1.182a.121.121,0,0,0,.121-.12,8.534,8.534,0,0,1,8.5-8.38,8.51,8.51,0,0,1,8.5,8.5,8.453,8.453,0,0,1-2.41,5.925,8.455,8.455,0,0,1-5.84,2.571Zm-2.862-3.375,3.458-3.458a.241.241,0,0,0,.071-.172v-4.891a.243.243,0,0,0-.243-.243h-1.335a.243.243,0,0,0-.243.243v4.187a.121.121,0,0,1-.036.086l-2.96,2.961a.242.242,0,0,0-.071.172.243.243,0,0,0,.071.172l.944.944a.243.243,0,0,0,.172.071A.241.241,0,0,0-3646.632,1790.354Z"
      transform="translate(3654.058 -1776.733)"
      fill="#2391dc"
    />
  </svg>
);
