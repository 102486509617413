"use client";

import { ReactNode, useEffect } from "react";
import TagManager from "react-gtm-module";
import { GTM_ID } from "~/lib/gtag";

const TagManagerWrapper = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_ID,
      events: {
        thankyou_page_view: "Page view of thankyou page",
      },
    });
  }, []);
  return <>{children}</>;
};

export default TagManagerWrapper;
