import { createContext } from "react";
import type { Context } from "react";

type SavedConditionPopupProps = {
  savedConditionPopupIsOpen: boolean;
  setSavedConditionPopupIsOpen: (value: boolean) => void;
  savedConditionCount: number;
  setSavedConditionCount: (value: number) => void;
};

export const SavedConditionPopupContext: Context<Partial<SavedConditionPopupProps>> = createContext(
  {},
);

type SurveyPopupProps = {
  surveyPopupIsOpen: boolean;
  setSurveyPopupIsOpen: (value: boolean) => void;
  surveyPopupIsOpenFromSavedCondition: boolean;
  setSurveyPopupIsOpenFromSavedCondition: (value: boolean) => void;
  shouldOpenSurveyPopup: boolean;
  setShouldOpenSurveyPopup: (value: boolean) => void;
};

export const SurveyPopupContext: Context<Partial<SurveyPopupProps>> = createContext({});

type GoogleMapIsOpenProps = {
  googleMapIsOpen: boolean;
  setGoogleMapIsOpen: (value: boolean) => void;
};

export const GoogleMapIsOpenContext: Context<Partial<GoogleMapIsOpenProps>> = createContext({});
