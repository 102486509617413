let localStorage: Storage;
if (typeof window !== "undefined") {
  localStorage = window.localStorage;
}

const STORAGE_KEY_PREFIX = "airdoor";

/**
 * LocalStorageのラッパークラス
 */
export default class LocalStorageUtil {
  /**
   * Get item from local storage.
   *
   * @param key
   */
  public static getItem<T>(key: string): T | null {
    if (!localStorage || !key) return null;

    try {
      const k = this.generateKey(key);
      const value = localStorage.getItem(k);
      if (value == null) return null;
      return <T>JSON.parse(value);
    } catch (err) {
      throw `LocalStorage's getItem error: ${JSON.stringify(err)}`;
    }
  }

  /**
   * Set item to local storage.
   *
   * @param key
   * @param value
   */
  public static setItem<T>(key: string, value: T): boolean {
    if (!localStorage || !key) return false;
    if (value == null) return false;

    try {
      const k = this.generateKey(key);
      localStorage.setItem(k, JSON.stringify(value));
      return true;
    } catch (err) {
      throw `LocalStorage's setItem error: ${JSON.stringify(err)}`;
    }
  }

  /**
   * Remove item from local storage.
   *
   * @param key
   */
  public static removeItem(key: string): boolean {
    if (!localStorage || !key) return false;

    const k = this.generateKey(key);
    localStorage.removeItem(k);
    return true;
  }

  /**
   * Generate storage key for airdoor.
   *
   * @param key
   */
  private static generateKey(key: string): string {
    return [STORAGE_KEY_PREFIX, key].join("-");
  }
}
