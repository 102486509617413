"use client";

import { ReactNode, useContext } from "react";
import styles from "./Layout.module.scss";
import SurveyModal from "@/common/modals/SurveyModal";
import Notification from "@/layouts/Common/Notification";
import Footer from "@/layouts/Footer/Footer";
import Header from "@/layouts/Header/Header";
import { SurveyPopupContext } from "~/contexts";

const Layout: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const { surveyPopupIsOpen, setSurveyPopupIsOpen } = useContext(SurveyPopupContext);
  const scrollTop = () => {
    const duration = 150;
    const currentY = window.pageYOffset;
    const step = duration / currentY > 1 ? 10 : 100;
    const timeStep = (duration / currentY) * step;
    const intervalId = setInterval(scrollUp, timeStep);

    function scrollUp() {
      const currentY = window.pageYOffset;
      if (currentY === 0) {
        clearInterval(intervalId);
      } else {
        scrollBy(0, -step);
      }
    }
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_ENV_NAME == "development" && (
        <div
          className={`${styles.env} ${styles.dev}`}
          onClick={(e) => {
            const t = e.target as HTMLElement;
            t.style.display = "none";
          }}
        >
          開発環境
        </div>
      )}
      {process.env.NEXT_PUBLIC_ENV_NAME == "local" && (
        <div
          className={`${styles.env} ${styles.local}`}
          onClick={(e) => {
            const t = e.target as HTMLElement;
            t.style.display = "none";
          }}
        >
          ローカル環境
        </div>
      )}
      <div className={styles.root}>
        <Header />
        <div className={`${styles.body} l-basic`}>
          <Notification></Notification>
          {children}
        </div>
        <Footer />
        <div className={styles.scrollTop} onClick={scrollTop}></div>
      </div>
      {surveyPopupIsOpen && (
        <SurveyModal isOpen={surveyPopupIsOpen} setIsOpen={setSurveyPopupIsOpen} />
      )}
    </>
  );
};

export default Layout;
