/** ハンバーガーアイコン */
export const Hamburger = (): JSX.Element => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1.76953C10.0556 1.76953 16.4975 1.76953 18.4615 1.76953"
      stroke="#2391DC"
      strokeWidth="3"
    />
    <path
      d="M0 8.12891C10.0556 8.12891 16.4975 8.12891 18.4615 8.12891"
      stroke="#2391DC"
      strokeWidth="3"
    />
    <path
      d="M0 14.4883C10.0556 14.4883 16.4975 14.4883 18.4615 14.4883"
      stroke="#2391DC"
      strokeWidth="3"
    />
  </svg>
);
